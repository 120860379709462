<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row">
			<div>
				<div class="blog single mt-n17">
					<div class="card shadow-lg">
						<h3 class="text-center  text-white mt-5 mx-5 py-3 rounded"
								style="background  : #880E4F">{{$t('form_welcome1')}}</h3>
						<div class="card-body">

							<div class="row gy-3 gx-xl-8">

								<form id="form"
											action="https://api.fraction.re/coffee/payment.php"
											method="post">
									<div class="row gx-md-8 gx-xl-12 gy-6">
										<div class="col ">

											<h4 class="mb-4">{{$t('form_welcome2')}}</h4>


											<div class="row g-3">

												<div class="col-12"
														 id="check_new_line">
													<div class="form-floating">
														<input type="text"
																	 class="form-control"
																	 name="public_key"
																	 id="key"
																	 placeholder="Stellar public key"
																	 required>
														<label for="key"
																	 class="form-label">{{$t('form_welcome3')}} <span class="text-muted"></span></label>
													</div>
												</div>

												<div class="col-12">
													<div class="form-check mb-4">
														<input value="new"
																	 @click="addkey()"
																	 id="check_new"
																	 type="checkbox"
																	 class="form-check-input">
														<label class="form-check-label"
																	 for="same-address">{{$t('form_welcome4')}}</label>
													</div>
												</div>
												<h4 class="mb-4">{{$t('form_welcome5')}}</h4>
												<div class="col-sm-6">
													<div class="form-floating">
														<input type="text"
																	 class="form-control"
																	 name="first_name"
																	 id="firstName"
																	 placeholder="First name"
																	 value=""
																	 required>
														<label for="firstName"
																	 class="form-label">{{$t('form_welcome6')}}</label>
														<div class="invalid-feedback">
															Valid first name is required.
														</div>
													</div>
												</div>
												<div class="col-sm-6">
													<div class="form-floating">
														<input type="text"
																	 class="form-control"
																	 name="last_name"
																	 id="lastName"
																	 placeholder="Last name"
																	 value=""
																	 required>
														<label for="lastName"
																	 class="form-label">{{$t('form_welcome7')}}</label>
														<!--/column -->

														<div class="invalid-feedback">
															Valid last name is required.
														</div>
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-floating">
														<input type="email"
																	 class="form-control"
																	 name="email"
																	 id="email"
																	 placeholder="you@example.com"
																	 required>
														<label for="email"
																	 class="form-label">Email</label>
														<div class="invalid-feedback">
															Please enter a valid email address for shipping
															updates.
														</div>
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-floating">
														<input type="phone"
																	 class="form-control"
																	 name="phone"
																	 id="phone"
																	 placeholder="Téléphone"
																	 required>
														<label for="phone"
																	 class="form-label">{{$t('form_welcome8')}}</label>
														<div class="invalid-feedback">
															Please enter a valid phone.
														</div>
													</div>
												</div>
												<div class="col-12">
													<div class="form-floating">
														<input type="text"
																	 class="form-control"
																	 name="address"
																	 id="address"
																	 placeholder="1234 Main St"
																	 required>
														<label for="address"
																	 class="form-label">{{$t('form_welcome9')}}</label>
														<div class="invalid-feedback">
															Please enter your shipping address.
														</div>
													</div>
												</div>
												<div class="col-12">
													<div class="form-floating">
														<input type="text"
																	 class="form-control"
																	 name="address2"
																	 id="address2"
																	 placeholder="Apartment or suite">
														<label for="address2"
																	 class="form-label">{{$t('form_welcome18')}} </label>
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-floating">
														<input type="text"
																	 class="form-control"
																	 name="zip"
																	 id="zip"
																	 placeholder="Zip Code"
																	 required>
														<label for="zip"
																	 class="form-label">{{$t('form_welcome10')}}</label>
														<div class="invalid-feedback">
															Zip code required.
														</div>
													</div>
												</div>
												<div class="col-md-5">
													<div class="form-floating">
														<input type="text"
																	 class="form-control"
																	 name="town"
																	 id="town"
																	 placeholder="Town"
																	 required>
														<label for="town"
																	 class="form-label">{{$t('form_welcome11')}} </label>
														<div class="invalid-feedback">
															Town required.
														</div>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-floating">
														<input type="text"
																	 value="France"
																	 class="form-control"
																	 name="country"
																	 id="country"
																	 placeholder="Country"
																	 required>
														<label for="country"
																	 class="form-label">{{$t('form_welcome12')}} </label>
														<div class="invalid-feedback">
															Pays required.
														</div>
													</div>
												</div>

											</div>


											<h4 class="my-4 mt-5">{{$t('form_welcome13')}}</h4>
											<div class="col-sm-12">
												<div class="form-floating">
													<input type="text"
																 class="form-control"
																 name="sponsor_key"
																 id="sponsor_key"
																 placeholder="Clé du sponsor"
																 value="">
													<label for="firstName"
																 class="form-label">{{$t('form_welcome14')}}</label>
													<i style="float:left;padding-left:10px; color:grey; font-size:90%"> {{$t('form_welcome15')}}</i>

												</div>
											</div>




											<hr class="mt-7 mb-6">



											<div class="col-12">
												<button class="btn btn-primary text-white"
																style="width:100%"
																type="submit">{{$t('form_welcome16')}} </button>
											</div><br />
											<i style="float:left;text-align:center;width:100%;padding-left:10px; color:grey; font-size:90%">
												{{$t('form_welcome17')}}</i>
										</div>
										<!-- /column -->

										<!-- /column -->


									</div>
									<!-- /.row -->
								</form>



							</div>
							<!--/.row -->

						</div>
						<!-- /.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.blog -->
			</div>
			<!-- /column -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container -->
</section>
</template>


<script>
export default {
	name: 'form_invest',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		addkey() {
			document.getElementById("key").value = "0000000000";
			if (document.getElementById("check_new").checked) document.getElementById("check_new_line").style = "display:none;";
			else
				document.getElementById("check_new_line").style = "display:block;";
		},

	},
	watch: {}
}
</script>

<style scoped>
</style>
